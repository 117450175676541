import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { useMetricsContext } from "providers/metricsProvider";
import { ComponentProps, forwardRef, useEffect, useRef } from "react";
import { useCountUp } from "react-countup";
import { Card } from "./Card";

type TransactionsProps = {
  title: string;
  color: ComponentProps<typeof Typography>[`color`];
};

const TransactionsBase = forwardRef<HTMLSpanElement | null, TransactionsProps>(
  function Transactions({ title, color }, ref) {
    return (
      <Card>
        <CardHeader title={title} />
        <Typography
          ref={ref}
          color={color}
          variant="h4"
          fontFamily="IBM Plex Mono"
        ></Typography>
        <Typography variant="caption">transactions</Typography>
      </Card>
    );
  }
);

export const MainnetTransactions = () => {
  const mainnetTx = useRef(null);
  const { mainnetCurrentTx, mainnetDestinationTx } = useMetricsContext();
  const { update: updateMainnetTx } = useCountUp({
    ref: mainnetTx,
    start: mainnetCurrentTx,
    end: mainnetDestinationTx,
    delay: 0,
    duration: 32,
    useEasing: false,
  });

  useEffect(() => {
    updateMainnetTx(mainnetDestinationTx);
  }, [updateMainnetTx, mainnetCurrentTx, mainnetDestinationTx]);

  return (
    <TransactionsBase
      ref={mainnetTx}
      title="Hedera Mainnet"
      color={(theme) => theme.palette.secondary.main}
    />
  );
};

export const TestnetTransactions = () => {
  const TestnetTx = useRef(null);
  const { testnetCurrentTx, testnetDestinationTx } = useMetricsContext();
  const { update: updateTestnetTx } = useCountUp({
    ref: TestnetTx,
    start: testnetCurrentTx,
    end: testnetDestinationTx,
    delay: 0,
    duration: 32,
    useEasing: false,
  });

  useEffect(() => {
    updateTestnetTx(testnetDestinationTx);
  }, [updateTestnetTx, testnetCurrentTx, testnetDestinationTx]);

  return (
    <TransactionsBase
      ref={TestnetTx}
      title="Hedera Testnet"
      color={(theme) => theme.palette.primary.main}
    />
  );
};
