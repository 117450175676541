import "../assets/fonts/ibm-plex/ibm-plex.css";
import "../assets/fonts/azonix/azonix.css";

export default function TypographyOverride() {
  const localeFontFamily = `IBM Plex Sans`;
  const localeWeightLight = 300;
  const localeWeightMedium = 400;
  const localeWeightRegular = 400;

  const fonts = [
    localeFontFamily,
    `Helvetica Neue`,
    `Arial`,
    `system-ui`,
    `sans-serif`,
  ];

  const fontFamily = fonts.map((font) => `"${font}"`).join(`,`);

  return {
    fontFamily,
    fontWeightBold: localeWeightRegular,
    fontWeightLight: localeWeightLight,
    fontWeightMedium: localeWeightMedium,
    fontWeightRegular: localeWeightRegular,
  };
}
