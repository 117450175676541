import { Box, CircularProgress, Grid, Stack, Typography, useTheme } from "@mui/material";

interface Props {
    label: string;
    backgroundColor: any;
    value: string;
    bigCircle: boolean;
}
export const CirclePercentage = (props: Props) => {
    const theme = useTheme();
    const { backgroundColor, value, label, bigCircle } = props;
    return (
        <Box sx={{ position: `relative`, display: `inline-flex` }}>
            <CircularProgress
                variant="determinate"
                value={100}
                size={100}
                style={{
                    width: bigCircle ? '280px' : '250px',
                    height: bigCircle ? '280px' : '250px',
                    borderRadius: `100%`,
                    border: `solid 1px ${theme.palette.grey[400]}`,
                    backgroundColor: backgroundColor,
                    color: backgroundColor
                }}
                thickness={22}
            />
            <Box
                sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: `absolute`,
                display: `flex`,
                alignItems: `center`,
                justifyContent: `center`,
                }}
            >   
                <Stack spacing={1} justifyContent='center' alignItems='center'>
                    <Typography color={`white`} variant="body1" component={`h6`}  >
                        {label}
                    </Typography>
                    <Typography color={`white`} component={`h4`} variant={bigCircle ? 'h4' : 'h6'} style={{ textAlign: `center` }} >
                        {value} 
                    </Typography>
                </Stack>
            </Box>
        </Box>
    );
};
