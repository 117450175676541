import "./index.css";
import CssBaseline from "@mui/material/CssBaseline";
import { MetricsContextProvider } from "providers/metricsProvider";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ThemeProvider from "./providers/themeProvider";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById(`root`) as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <CssBaseline />
      <MetricsContextProvider>
        <App />
      </MetricsContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
