import { Theme, useMediaQuery } from "@mui/material";
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArkhiaLogo from "assets/imgs/branding/arkhia-typeface-light.png";
import { arkhiaExplorer, arkhiaIo, arkhiaStatus } from "constants/links";
import { Link } from "./Link";

const StyledAppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginBottom: theme.spacing(2),
  "& .MuiStack-root .MuiTypography-root": {
    color: theme.palette.secondary.main,
  },
  "& .MuiToolbar-root": {
    maxWidth: theme.breakpoints.values.md,
    marginInline: `auto`,
    width: `100%`,
    padding: theme.spacing(0, 1),
  },
}));

export const AppBar = (props: AppBarProps) => {
  const isSmDown = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down(`sm`)
  );
  const isDesktop = useMediaQuery(`(min-width: 400px)`);
  return (
    <StyledAppBar position="static" {...props}>
      <Toolbar>
        <Stack direction="row" spacing={1} mr="auto">
          <a
            href={arkhiaIo}
            target="_blank"
            style={{ display: `flex`, alignItems: `center` }}
            rel="noreferrer"
          >
            <img
              src={ArkhiaLogo}
              alt="Arkhia Logo"
              style={{ maxHeight: 32, maxWidth: 130, objectFit: `contain` }}
            />
          </a>
          <Typography lineHeight={2} variant={isSmDown ? `body1` : `h6`}>
            Metrics
          </Typography>
        </Stack>
        {isDesktop && (
          <Stack direction="row" spacing={1}>
            <Link target="_blank" href={arkhiaStatus}>
              Service Status
            </Link>
            <Link target="_blank" href={arkhiaExplorer}>
              Explorer
            </Link>
          </Stack>
        )}
      </Toolbar>
    </StyledAppBar>
  );
};
