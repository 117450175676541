import { Components } from "@mui/material";

export default function Overrides(): Components {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          transition: `font-size 300ms cubic-bezier(0.4, 0, 0.2, 1)`,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 300,
      },
    },
    MuiCard: {
      defaultProps: {
        variant: `outlined`,
      },
    },
  };
}
