import "./App.css";
import { Container, Divider, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { AppBar } from "components/AppBar";
import { BottomAppBar } from "components/BottomNavigation";
import { DividerWithText } from "components/DividerWithText";
import { Footer } from "components/Footer";
import { Sponsors } from "components/Sponsors";
import { MainnetTps, TestnetTps } from "components/Tps";
import {
    MainnetTransactions,
    TestnetTransactions,
} from "components/Transactions";
import { NewAccountsCreated, TotalAccounts, TransactionsPerHour } from "pages/HistoricalMetrics";

function App() {
    const theme = useTheme();
    const queryParameters = new URLSearchParams(window.location.search);
    const beta = queryParameters.get(`beta`) === `true`;
    return (
        <>
            <AppBar />
            <Stack
                px={1}
                justifyContent="space-between"
                alignItems="center"
                flexGrow={1}
                pb={{ xs: 7, sm: 0 }}
            >
                <Container disableGutters maxWidth="md">
                    <Grid container spacing={2}>
                        <DividerWithText text="Transactions"></DividerWithText>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={2}>
                                <MainnetTransactions />
                                <MainnetTps />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={2}>
                                <TestnetTransactions />
                                <TestnetTps />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Stack spacing={2}>
                                <TransactionsPerHour />
                            </Stack>
                        </Grid>
                        <DividerWithText text="Accounts"></DividerWithText>
                        <Grid item xs={12} sm={12}>
                            <Stack spacing={2}>
                                <TotalAccounts />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Stack spacing={2}>
                                <NewAccountsCreated />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{ marginTop: theme.spacing(4) }}>
                            <Sponsors></Sponsors>
                        </Grid>
                    </Grid>
                </Container>
                <Footer />
            </Stack>
            <BottomAppBar />
        </>
    );
}

export default App;
