import MuiLinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

type LinearProgressPropsOverride = Omit<LinearProgressProps, `color`> & {
  color:
    | `inherit`
    | `primary`
    | `secondary`
    | `error`
    | `info`
    | `success`
    | `complimentary`
    | `warning`;
};

const StyledLinearProgress = styled(
  MuiLinearProgress as (props: LinearProgressPropsOverride) => JSX.Element
)(() => ({}));

export const LinearProgress = (props: LinearProgressPropsOverride) => (
  <StyledLinearProgress variant="determinate" {...props} />
);
