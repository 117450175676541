
export interface Network {
    id: number;
    name: string;
    network_id: number;
}
export const NETWORK: Array<Network> = [
    {
        id: 1,
        name: `Hedera Mainnet`,
        network_id: 295,
    },
    {
        id: 2,
        name: `Hedera Testnet`,
        network_id: 296
    }
];

export enum NETWORK_ID {
    MAINNET = 1,
    TESTNET = 2
}