import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import SearchIcon from "@mui/icons-material/Search";
import BottomNavigation, {
  BottomNavigationProps,
} from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArkhiaGlyph from "assets/imgs/branding/arkhia-glyph-square-white.png";
import { arkhiaExplorer, arkhiaIo, arkhiaStatus } from "constants/links";

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  backgroundColor: `inherit`,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  "& :is(.MuiBottomNavigationAction-label, .MuiSvgIcon-root)": {
    color: `white`,
  },
  "& .MuiBottomNavigationAction-root": {
    flexGrow: 0,
    padding: 0,
    "&:hover": {
      backgroundColor: `#264190`,
    },
  },
}));

export const BottomAppBar = (props: BottomNavigationProps) => {
  const isMobile = useMediaQuery(`(max-width: 400px)`);
  return isMobile ? (
    <>
      <Paper
        sx={(theme) => ({
          position: `fixed`,
          bottom: 0,
          left: 0,
          right: 0,
          background: theme.palette.primary.main,
        })}
        elevation={3}
      >
        <StyledBottomNavigation {...props}>
          <a
            href={arkhiaIo}
            target="_blank"
            style={{ flexGrow: 1, display: `flex`, alignItems: `center` }}
            rel="noreferrer"
          >
            <img
              src={ArkhiaGlyph}
              alt="Arkhia Logo"
              style={{
                maxHeight: 40,
                objectFit: `contain`,
              }}
            />
          </a>
          <BottomNavigationAction
            showLabel
            href={arkhiaStatus}
            target="_blank"
            label="Status"
            icon={<MonitorHeartIcon />}
          />
          <BottomNavigationAction
            showLabel
            href={arkhiaExplorer}
            target="_blank"
            label="Explorer"
            icon={<SearchIcon />}
          />
        </StyledBottomNavigation>
      </Paper>
    </>
  ) : (
    <></>
  );
};
