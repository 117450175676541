import {
    IconButton as MuiIconButton,
    IconButtonProps,
    styled,
    Tooltip,
    Typography
} from '@mui/material';
import React from 'react';

interface Props extends IconButtonProps {
    icon: React.ReactNode;
    text?: string;
    onClick: () => void;
    ariaLabel?: string;
    tooltipLabel?: string;
}

const IconButton = styled(MuiIconButton)(({ theme }) => ({ '&:hover': { color: theme.palette.primary.main } }));

export const ButtonWithIcon = ({ icon, text, onClick, ariaLabel, tooltipLabel, sx, ...rest }: Props) => {
    const handleClick = () => {
        onClick();
    };
    const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Tooltip title={tooltipLabel !== undefined ? tooltipLabel : ``}>
            <IconButton
                aria-label={ariaLabel}
                edge="start"
                sx={{
                    margin: 0,
                    ...sx,
                }}
                onClick={handleClick}
                onMouseDown={handleMouseDown}
                {...rest}
            >
                <Typography variant="subtitle1">{text}</Typography>
                {icon}
            </IconButton>
        </Tooltip>
    );
};
