import {
    Divider,
    DividerProps,
    Link,
    LinkProps,
    styled,
    useTheme
} from '@mui/material';

const StyledImg = styled(`img`)(() => ({
    fontFamily: `Azonix, Arial, -apple-system, Segoe UI, Helvetica, sans-serif`,
    objectFit: `contain`,
    opacity: `0.3`,
    height: 12
}));

const StyledLink = styled((props: LinkProps) => <Link underline="hover" variant="caption" {...props} rel="noopener" />)(
    ({ theme }) => ({
        cursor: `pointer`,
        color: theme.palette.grey[800],
    })
);

const VerticalDivider = styled(({ ...props }: DividerProps) => {
    const theme = useTheme();
    return <Divider flexItem orientation="vertical" sx={{ borderColor: theme.palette.primary.main }} {...props} />;
})(({ theme }) => ({
    height: theme.spacing(2),
    alignSelf: `center`,
}));

export {
    StyledImg, StyledLink, VerticalDivider
};
