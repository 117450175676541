import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { ReactComponent as MediumLight } from "../../../assets/imgs/social-media/medium-light.svg";

const BCWGroupUrl = `https://www.bcw.group/`;
const privacyUrl = `https://www.arkhia.io/privacy/`;
const termsUrl = `https://www.arkhia.io/terms/`;

const cookiesUrl = `https://www.arkhia.io/cookies/`;

const twitterUrl = `https://twitter.com/Arkhia_io/`;
const linkedinUrl = `https://www.linkedin.com/company/arkhia/`;
const mediumUrl = `https://medium.com/@Arkhia`;

const tncLinks = [
  {
    text: `Privacy Policy`,
    url: privacyUrl,
    noDivider: true,
  },
  {
    text: `Terms of Service`,
    url: termsUrl,
  },
  {
    text: `Cookies`,
    url: cookiesUrl,
  },
];

const socialMediaLinks = [
  {
    url: twitterUrl,
    icon: <TwitterIcon fontSize="small" />,
    text: `Twitter`,
  },
  {
    url: linkedinUrl,
    icon: <LinkedInIcon fontSize="small" />,
    text: `LinkedIn`,
  },
  {
    url: mediumUrl,
    icon: (
      <MediumLight
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall"
        fontSize="small"
        style={{
          fill: `currentcolor`,
          display: `inline-block`,
          width: `1em`,
          height: `1em`,
        }}
      />
    ),
    text: `Medium`,
  },
];

export { BCWGroupUrl, socialMediaLinks, tncLinks };
