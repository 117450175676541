import {
    Divider,
    styled,
    Typography
} from '@mui/material';

const DividerRoot = styled(`div`)(({ theme }) => ({
    width: `100%`,
    paddingLeft: theme.spacing(2),
    margin: `${theme.spacing(3)} 0 ${theme.spacing(1)}`,
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': { marginTop: theme.spacing(2) },
}));

export const DividerWithText = ({ text }: {text: string}) => (
    <DividerRoot>
        <Divider>
            <Typography component="h6" variant="body1">{text}</Typography>
        </Divider>
    </DividerRoot>
);
