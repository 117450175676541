import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";

export const Card = styled(MuiCard)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  "& .MuiCardHeader-title": {
    fontSize: theme.typography.htmlFontSize,
  },
  "& .MuiCardHeader-root": {
    paddingInline: theme.spacing(0.5),
    paddingBottom: theme.spacing(2),
  },
  "& .MuiCardHeader-content .MuiTypography-root": {
    fontWeight: 500,
  },
}));
