import { Container, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    RadialLinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import { NETWORK, Network, NETWORK_ID } from 'constants/network';
import { AggregatedLineChartBucketObject } from 'providers/metricsProvider';
import { ComponentProps, MutableRefObject } from 'react';
import {  Line } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    RadialLinearScale,
    ArcElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


interface PropsSimplified {
    data: any;
    primaryColor: ComponentProps<typeof Typography>[`color`];
    secondaryColor: ComponentProps<typeof Typography>[`color`];
    stepSize: number;
}

export function LineChartSimplified(props: PropsSimplified) {
    const { data, stepSize } = props;
    const theme = useTheme();

    const options = {
        responsive: true,
        plugins: {
            legend: { position: `top` as const },
            title: {
                display: false,
                text: ``,
            },
        },
        maintainAspectRatio: false,
        scale: {
            ticks: {
                beginAtZero: true,
                min: 0,
                stepSize: stepSize,
                distribution: `linear`,
            },
        },
    };
    const buildChartData = (_data: MutableRefObject<AggregatedLineChartBucketObject>) => {
        const { labels, mainnet, testnet } = _data.current;
     
   
        const data = {
            labels,
            datasets: [
                {
                    label: NETWORK.find((item: Network) => item.id === NETWORK_ID.MAINNET)?.name,
                    data: mainnet,
                    backgroundColor: theme.palette.secondary.main,
                },
                {
                    label: NETWORK.find((item: Network) => item.id === NETWORK_ID.TESTNET)?.name,
                    data: testnet,
                    backgroundColor: theme.palette.primary.main,
                },
            ],
        };
        return data;
    };

    return (
        <Container maxWidth={false}>
            <Line data={buildChartData(data)} height={300} options={options} />
        </Container>
    );
}
