import {
    Box,
    Divider,
    Stack,
    Theme,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { ButtonWithIcon } from 'components/Button/ButtonWithIcon';
import React from 'react';
import { getCurrentYear, pathToExternalUrl } from 'utils/utils';
import ArkhiaGlyphLight from '../../assets/imgs/branding/arkhia-typeface-light.png';
import BCWGlyphLight from '../../assets/imgs/branding/bcw_logo_glyph_font.BLK.32px.png';
import {
    BCWGroupUrl,
    socialMediaLinks,
    tncLinks
} from './shared/constants';
import {
    StyledImg,
    StyledLink,
    VerticalDivider} from './shared/styled';

export const Footer = () => {
    const theme = useTheme();
    const year = getCurrentYear();

    const isMdDown = useMediaQuery<Theme>((theme) =>
        theme.breakpoints.down(`md`)
    );

    return (
        <Stack alignItems="center" flexDirection="column" my={theme.spacing(4)}>
            <Divider
                sx={{
                    my: theme.spacing(2),
                    width: `75%`,
                }}
            />
            <Stack alignItems="center" direction={isMdDown ? `column` : `row`} spacing={1}>
                {isMdDown && (
                    <StyledImg
                        alt="Arkhia Logo"
                        height={12}
                        src={ArkhiaGlyphLight}
                        sx={{ cursor: `pointer` }}
                        onClick={() => pathToExternalUrl(`https://arkhia.io/`)}
                    />
                )}
                <Typography color={theme.palette.grey[400]} variant="caption">
                    <Box alignItems="center" display="flex">
                        ©&nbsp;
                        {year}
                        &nbsp;Arkhia Inc. All rights reserved. Powered by
                        <StyledImg
                            alt="BCW Logo"
                            height={12}
                            src={BCWGlyphLight}
                            sx={{
                                marginLeft: theme.spacing(0.5),
                                cursor: `pointer`,
                            }}
                            onClick={() => pathToExternalUrl(BCWGroupUrl)}
                        />
                    </Box>
                </Typography>
                <Stack alignItems="center" direction="row" spacing={1}>
                    {tncLinks.map((link) => (
                        <React.Fragment key={link.text}>
                            {!link.noDivider && <VerticalDivider />}
                            <StyledLink onClick={() => pathToExternalUrl(link.url)}>{link.text}</StyledLink>
                        </React.Fragment>
                    ))}
                </Stack>
            </Stack>
            <Stack alignItems="center" direction="row" mt={theme.spacing(1)} spacing={1}>
                {socialMediaLinks.map((link) => (
                    <React.Fragment key={link.url}>
                        <ButtonWithIcon
                            disableRipple
                            icon={link.icon}
                            sx={{
                                padding: 0,
                                backgroundColor: `transparent`,
                            }}
                            onClick={() => pathToExternalUrl(link.url)}
                        />
                    </React.Fragment>
                ))}
            </Stack>
        </Stack>
    );
};
