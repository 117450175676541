import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMetricsContext } from "providers/metricsProvider";
import { ComponentProps, useRef } from "react";
import { Card } from "./Card";
import { LinearProgress } from "./LinearProgress";

type TpsBaseProps = {
  title: string;
  progressColor: ComponentProps<typeof LinearProgress>[`color`];
  tps: number;
  maxTps: number;
};

const TpsBase = ({ title, progressColor, tps, maxTps }: TpsBaseProps) => {
  const textColor = progressColor === `inherit` ? `primary` : progressColor;
  const value = Math.min((tps / Math.max(1, maxTps)) * 100, 100);

  return (
    <Card>
      <CardHeader title={title} />
      <LinearProgress color={progressColor} value={value} />
      <Stack
        my={1}
        direction="row"
        justifyContent="space-between"
        alignItems="end"
      >
        <Typography
          color={(theme) => theme.palette[textColor].main}
          variant="h4"
          fontFamily="IBM Plex Mono"
        >
          { tps == 0 ? `` : `~` }
          {tps} tps
        </Typography>
        <Stack direction="row" spacing={1}>
          <Typography variant="caption" component="span">max:</Typography>
          <Typography variant="caption" component="span" fontFamily="IBM Plex Mono">{maxTps} tps</Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export const MainnetTps = () => {
  const { mainnetTps } = useMetricsContext();
  const maxMainnetTps = useRef(mainnetTps);
  maxMainnetTps.current = Math.max(maxMainnetTps.current, mainnetTps);
  return (
    <TpsBase
      title="Hedera Mainnet"
      progressColor="secondary"
      tps={mainnetTps}
      maxTps={maxMainnetTps.current}
    />
  );
};

export const TestnetTps = () => {
  const { testnetTps } = useMetricsContext();
  const maxTestnetTps = useRef(testnetTps);
  maxTestnetTps.current = Math.max(maxTestnetTps.current, testnetTps);
  return (
    <TpsBase
      title="Hedera Testnet"
      progressColor="primary"
      tps={testnetTps}
      maxTps={maxTestnetTps.current}
    />
  );
};
