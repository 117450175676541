export const BCWGroupUrl = `https://www.bcw.group/`;
export const privacyUrl = `https://www.arkhia.io/privacy/`;
export const termsUrl = `https://www.arkhia.io/terms/`;
export const cookiesUrl = `https://www.arkhia.io/cookies/`;
export const twitterUrl = `https://twitter.com/Arkhia_io/`;
export const linkedinUrl = `https://www.linkedin.com/company/arkhia/`;
export const mediumUrl = `https://medium.com/@Arkhia`;
export const arkhiaIo = `https://www.arkhia.io/`;
export const arkhiaStatus = `https://status.arkhia.io/`;
export const arkhiaExplorer = `https://explorer.arkhia.io/`;
export const hashpackLink = `https://www.hashpack.app/`;
