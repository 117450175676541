
import {   Grid, Stack, useTheme } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { CirclePercentage } from "components/CirclePercentage";
import { useMetricsContext } from "providers/metricsProvider";
import { ComponentProps, forwardRef, useEffect, useRef } from "react";
import { MetricsUtils } from "utils/utils";
import { Card } from "../components/Card";
import { LineChartSimplified } from "../components/LineChart";

type MetricsProps = {
    title: string;
    primaryColor: ComponentProps<typeof Typography>[`color`];
    secondaryColor: ComponentProps<typeof Typography>[`color`];
    stepSize: number;
};

type TotalAccountsProps = {
    title: string;
    colorPrimary: ComponentProps<typeof Typography>[`color`];
    colorSecondary: ComponentProps<typeof Typography>[`color`];
};

const TotalAccountsBase = forwardRef<HTMLSpanElement | null, TotalAccountsProps>(
    function TotalAccounts({ title  }, ref) {
        const mainnetValue = (ref as any)?.current?.mainnet;
        const testnetValue = (ref as any)?.current?.testnet;
        const theme = useTheme();
    
        return (
            <Card sx={{paddingBottom: theme.spacing(2)}}>
                <CardHeader title={title} />
                <Grid container>
                    <Grid item xs={12} md={6} sx={{paddingLeft: theme.spacing(4)}}>
                        <Stack direction="row" spacing={1} justifyContent='center'>
                            <CirclePercentage 
                                label={`Mainnet`} 
                                backgroundColor={theme.palette.secondary.main}
                                value={MetricsUtils.thousandSeparatorParser((ref as any)?.current?.mainnet)}
                                bigCircle={mainnetValue > testnetValue || mainnetValue === testnetValue} />
                        </Stack>  
                    </Grid>
                    <Grid item xs={12} md={6}>    
                        <Stack direction="row" spacing={1} justifyContent='center'>  
                            <CirclePercentage 
                                label={`Testnet`} 
                                backgroundColor={theme.palette.primary.main} 
                                value={MetricsUtils.thousandSeparatorParser((ref as any)?.current?.testnet)} 
                                bigCircle={testnetValue > mainnetValue || mainnetValue === testnetValue} />
                        </Stack>
                    </Grid> 
                </Grid>
            </Card>
        );
    }
);

const MetricsBase = forwardRef<HTMLSpanElement | null, MetricsProps>(
    function Transactions({ title, primaryColor, secondaryColor, stepSize }, ref) {
        return (
            <Card>
                <CardHeader title={title} />
                <LineChartSimplified stepSize={stepSize} data={ref} primaryColor={primaryColor} secondaryColor={secondaryColor} />
            </Card>
        );
    }
);

export const TotalAccounts = () => {
    const { mainnetTotalAccounts, testnetTotalAccounts } = useMetricsContext();
    const totalAccounts = useRef({} as any);
    totalAccounts.current = {
        mainnet: mainnetTotalAccounts,
        testnet: testnetTotalAccounts,
    }
    useEffect(() => {
        totalAccounts.current = {
            mainnet: mainnetTotalAccounts,
            testnet: testnetTotalAccounts,
        }
    }, [mainnetTotalAccounts, testnetTotalAccounts]);
    return (
        <TotalAccountsBase
            ref={totalAccounts}
            title="Accounts Created"
            colorPrimary={(theme) => theme.palette.secondary.main}
            colorSecondary={(theme) => theme.palette.primary.main}
        />
    )
};

export const NewAccountsCreated = () => {
    const { accountsCreatedByDateAggregated } = useMetricsContext();
    const newAccountsLineData = useRef({
        labels: [],
        mainnet: [],
        testnet: [],
    } as any);


    newAccountsLineData.current = accountsCreatedByDateAggregated;
    useEffect(() => {
        if (accountsCreatedByDateAggregated === null || accountsCreatedByDateAggregated === undefined) return;
        newAccountsLineData.current = accountsCreatedByDateAggregated;
    }, [accountsCreatedByDateAggregated]);


    return (

        <MetricsBase
            ref={newAccountsLineData}
            title="Account Timeline"
            stepSize={10000}
            primaryColor={(theme) => theme.palette.primary.main}
            secondaryColor={(theme) => theme.palette.secondary.main}
        />
    );
};


export const TransactionsPerHour = () => {
    const { transactionsCreatedHourlyAggregated } = useMetricsContext();
    const transactionsHourData = useRef({} as any);
    transactionsHourData.current = transactionsCreatedHourlyAggregated;
    useEffect(() => {
        transactionsHourData.current = transactionsCreatedHourlyAggregated;
    }, [transactionsCreatedHourlyAggregated]);

    return (
        <MetricsBase
            ref={transactionsHourData}
            stepSize={1000000}
            title="Transactions Per Hour"
            primaryColor={(theme) => theme.palette.primary.main}
            secondaryColor={(theme) => theme.palette.secondary.main} />
    );
};
