import { createContext, useContext, useState } from "react";
import useWebSocket from "react-use-websocket";
import { MetricsUtils } from "utils/utils";

export interface AccountsCreatedBucketObject {
  [date: number]: string;
}

export interface TransactionsCreatedHourlyBucketObject {
  [hour: number]: string;
}

export interface AggregatedLineChartBucketObject {
  labels: string[];
  mainnet: string[];
  testnet: string[];
}

type IMetricsContext = {
  testnetTps: number;
  mainnetTps: number;
  testnetCurrentTx: number;
  testnetDestinationTx: number;
  mainnetCurrentTx: number;
  mainnetDestinationTx: number;
  mainnetTotalAccounts: number;
  testnetTotalAccounts: number;
  mainnetAccountsCreatedByDate: AccountsCreatedBucketObject;
  testnetAccountsCreatedByDate: AccountsCreatedBucketObject;
  accountsCreatedByDateAggregated: AggregatedLineChartBucketObject;
  mainnetTransactionsCreatedHourly: TransactionsCreatedHourlyBucketObject;
  testnetTransactionsCreatedHourly: TransactionsCreatedHourlyBucketObject;
  transactionsCreatedHourlyAggregated: AggregatedLineChartBucketObject;
};

const MetricsContext = createContext<IMetricsContext>({
  testnetTps: 0,
  mainnetTps: 0,
  testnetCurrentTx: 0,
  testnetDestinationTx: 0,
  mainnetCurrentTx: 0,
  mainnetDestinationTx: 0,
  mainnetTotalAccounts: 0,
  testnetTotalAccounts: 0,
  mainnetAccountsCreatedByDate: [],
  testnetAccountsCreatedByDate: [],
  accountsCreatedByDateAggregated: {
    labels: [],
    mainnet: [],
    testnet: [],
  },
  mainnetTransactionsCreatedHourly: [],
  testnetTransactionsCreatedHourly: [],
  transactionsCreatedHourlyAggregated: {
    labels: [],
    mainnet: [],
    testnet: [],
  },
});

const pageUrl = document.location.href.split(`/`)[2];
const secureWS = !(process.env.REACT_APP_SSL_WS === `false`);
const socketUrl =
  process.env.REACT_APP_OVERRIDE_WS_URL ||
  `${secureWS ? `wss` : `ws`}://${pageUrl}`;

export const MetricsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [testnetTps, setTestnetTps] = useState(0);
  const [mainnetTps, setMainnetTps] = useState(0);
  const [testnetCurrentTx, setTestnetCurrentTx] = useState(0);
  const [testnetDestinationTx, setTestnetDestinationTx] = useState(0);
  const [mainnetCurrentTx, setMainnetCurrentTx] = useState(0);
  const [mainnetDestinationTx, setMainnetDestinationTx] = useState(0);

  const [mainnetTotalAccounts, setMainnetTotalAccounts] = useState(0);
  const [testnetTotalAccounts, setTestnetTotalAccounts] = useState(0);

  const [mainnetAccountsCreatedByDate, setMainnetAccountsCreatedByDate] = useState([]);
  const [testnetAccountsCreatedByDate, setTestnetAccountsCreatedByDate] = useState([]);
  const [accountsCreatedByDateAggregated, setAccountsCreatedByDateAggregated] = useState({
    labels: [],
    mainnet: [],
    testnet: [],
  } as AggregatedLineChartBucketObject);
  const [mainnetTransactionsCreatedHourly, setMainnetTransactionsCreatedHourly] = useState([]);
  const [testnetTransactionsCreatedHourly, setTestnetTransactionsCreatedHourly] = useState([]);
  const [transactionsCreatedHourlyAggregated, setTransactionsCreatedHourlyAggregated] = useState({
    labels: [],
    mainnet: [],
    testnet: [],
  } as AggregatedLineChartBucketObject);


  useWebSocket(socketUrl, {
    shouldReconnect: () => true,
    onMessage: (event) => {
      const data = JSON.parse(event.data);
      if (mainnetCurrentTx === 0)
        setMainnetCurrentTx(data.mainnet.previous);
      if (testnetCurrentTx === 0)
        setTestnetCurrentTx(data.testnet.previous);
      setMainnetDestinationTx(data.mainnet.current);
      setTestnetDestinationTx(data.testnet.current);
      setTestnetTps(data.testnet?.tps ? data.testnet.tps.toFixed(0) : '-');
      setMainnetTps(data.mainnet?.tps ? data.mainnet.tps.toFixed(0) : '-');

      setMainnetTotalAccounts(data.mainnet.historicalMetrics.totalAccounts);
      setTestnetTotalAccounts(data.testnet.historicalMetrics.totalAccounts);

      // Accounts
      const labelsAccountsCreated = Array.from(new Set([
        ...Object.keys(data.mainnet.historicalMetrics.accountsCreatedByDate),
        ...Object.keys(data.testnet.historicalMetrics.accountsCreatedByDate)
      ]))
        .sort((a, b) => new Date(+a).getTime() - new Date(+b).getTime());

      const aggregatedAccountsCreated = {
        labels: labelsAccountsCreated.map((label) => MetricsUtils.formatDateToUSLocaleFromDateObj(new Date(+label))),
        mainnet: labelsAccountsCreated.map((label) => data.mainnet.historicalMetrics.accountsCreatedByDate[label] || `0`),
        testnet: labelsAccountsCreated.map((label) => data.testnet.historicalMetrics.accountsCreatedByDate[label] || `0`),
      };
      setAccountsCreatedByDateAggregated(aggregatedAccountsCreated)

      setMainnetAccountsCreatedByDate(data.mainnet.historicalMetrics.accountsCreatedByDate);
      setTestnetAccountsCreatedByDate(data.testnet.historicalMetrics.accountsCreatedByDate);

      // Transactions
      const labelsTransactionCreatedHourly = Array.from(new Set([
        ...Object.keys(data.mainnet.historicalMetrics.transactionsCreatedHourly),
        ...Object.keys(data.testnet.historicalMetrics.transactionsCreatedHourly)
      ]))
        .sort((a, b) => new Date(+a).getTime() - new Date(+b).getTime());

      const aggregatedTransactionsCreatedHourly = {
        labels: labelsTransactionCreatedHourly.map((label) => new Date(+label).toLocaleTimeString()),
        mainnet: labelsTransactionCreatedHourly.map((label) => data.mainnet.historicalMetrics.transactionsCreatedHourly[label] || `0`),
        testnet: labelsTransactionCreatedHourly.map((label) => data.testnet.historicalMetrics.transactionsCreatedHourly[label] || `0`),
      };

      setTransactionsCreatedHourlyAggregated(aggregatedTransactionsCreatedHourly);

      setMainnetTransactionsCreatedHourly(data.mainnet.historicalMetrics.transactionsCreatedHourly);
      setTestnetTransactionsCreatedHourly(data.testnet.historicalMetrics.transactionsCreatedHourly);
    },
  });

  return (
    <MetricsContext.Provider
      value={{
        testnetTps,
        mainnetTps,
        testnetCurrentTx: testnetCurrentTx,
        testnetDestinationTx: testnetDestinationTx,
        mainnetCurrentTx: mainnetCurrentTx,
        mainnetDestinationTx: mainnetDestinationTx,
        mainnetTotalAccounts: mainnetTotalAccounts,
        testnetTotalAccounts: testnetTotalAccounts,
        mainnetAccountsCreatedByDate: mainnetAccountsCreatedByDate,
        testnetAccountsCreatedByDate: testnetAccountsCreatedByDate,
        accountsCreatedByDateAggregated: accountsCreatedByDateAggregated,
        mainnetTransactionsCreatedHourly: mainnetTransactionsCreatedHourly,
        testnetTransactionsCreatedHourly: testnetTransactionsCreatedHourly,
        transactionsCreatedHourlyAggregated: transactionsCreatedHourlyAggregated
      }}
    >
      {children}
    </MetricsContext.Provider>
  );
};

export const useMetricsContext = () => {
  const value = useContext(MetricsContext);
  if (!value) throw `Metrics Context must be consumed within provider`;
  return value;
};
